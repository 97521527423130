import { ConnectionComposables } from "@/composables/connection/connection.composable";
import {
  Section,
  SectionType,
} from "@/composables/section/models/section.model";
import { useSectionComposable } from "@/composables/section/section.composable";
import SocialLinks from "@/helpers/models/sociallinks.model";
import User from "@/helpers/models/user.model";
import { RouteEnum } from "@/router/routeNames";
import firebaseService from "@/services/firebase.service";
import httpService from "@/services/http.service";
import storageService from "@/services/storage.service";
import utilService from "@/services/util.service";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { TYPE } from "vue-toastification";
import { ProfileEntity, ProfilePrivacy } from "./profile.entity";
import { SectionObject } from "./section"; // Adjust the path as needed
import { SettingConfigurationStatus } from "@/helpers/models/settings.model";
const profileData = ref<ProfileEntity>();
const images = ref<any>({
  profilePicture: "",
  coverPhoto: "",
});
const publicWatchlist = ref<any>([]);
const publicBestTrades = ref<any>([]);
const publicInfluencers = ref<any>([]);
const privateWatchlist = ref<any>([]);
const singleWatchlistData = ref<any>();
const errorUserName = ref<string>("");
const isEditableView = ref<boolean>(false);
const userName = ref<string>("");
const sharedObject = ref<SectionObject>({
  sectionId: null,
  sectionImage: null,
  sectionType: null,
  comment: null,
  platform: null,
});

const isProfileChanging = ref<boolean>(false);
const isProfileLoading = ref<boolean>(false);

export const useProfileScreenComposable = () => {
  const {
    unfollow,
    onFollow,
    isFollowingLoading,
    sendFriendRequest,
    requestReaction,
    onCancelRequest,
  } = ConnectionComposables();
  const { mapSectionValue, updateAllSection, mapSectionFromProfileSection } =
    useSectionComposable();
  var profileSectionRef = ref<any>([]);
  const router = useRouter();
  const aboutUser = ref<any>();
  const isTruncated = ref<boolean>(false);
  const isProfileNameEditable = ref<boolean>(false);
  const isProfileBioEditable = ref<boolean>(false);
  const isProfileUploading = ref<boolean>(false);
  const isCoverUploading = ref<boolean>(false);
  const moreLess = ref(true);
  const tabs = [
    {
      title: 'Profile',
      value:'About',
      disabled: false,
      icon:'info-icon.svg'
    },
    {
      title: 'Timeline',
      value:'timeline',
      disabled: false,
      icon:'timeline-icon.svg'
    },
    {
      title: 'Network',
      value:'network',
      disabled: false,
      icon:'network-icon.svg'
    },
    {
      title: 'Investment',
      value:'Investment',
      disabled: false,
      icon:'dollar-circle.svg'
    },
    {
      title: 'Accounts',
      value:'Accounts',
      disabled: false,
      icon:'wallet-icon.svg'
    },
  ]
  const myElement = ref<HTMLElement | null>(null);
  const rightValue = ref(0);

  const loadProfileScreen = async (username: string) => {
    if (username) {
      if (username != userName.value) {
        userName.value = username;
        if (userName.value.startsWith("@")) {
          userName.value = userName.value.substring(1);
        }
        await getProfileData(true);
      }
    }
  };

  const updateComputedProperties = () => {
    requestAnimationFrame(() => {
      if (myElement.value) {
        const rect = myElement.value.getBoundingClientRect();
        rightValue.value = rect.right;
      }
    });
  };

  const getProfileData = async (showSplash = true) => {
    if (showSplash) {
      profileData.value = undefined;
      isEditableView.value = false;
    }
    if (userName) {
      try {
        if (showSplash) {
          isProfileLoading.value = true;
        }
        var res = await httpService.getProfileData(userName.value);
        isProfileLoading.value = false;
        window.removeEventListener("resize", updateComputedProperties);
        if (res.data.isSuccess && res.data.data) {
          let resData = res.data.data;
          var obj = new ProfileEntity();
          profileData.value = undefined;
          isEditableView.value = false;
          profileData.value = Object.assign(obj, resData);
          var currentUser = storageService.getUser();
          if (
            currentUser &&
            currentUser._id &&
            currentUser._id == profileData.value!._id
          ) {
            const notificationReadTime = currentUser?.notificationReadTime;
            if(profileData.value) {
              const clonedProfile = JSON.parse(JSON.stringify(profileData.value))
              if(!clonedProfile?.notificationReadTime ||
                (clonedProfile?.notificationReadTime && !Object.keys(clonedProfile?.notificationReadTime).length)) {
                storageService.setUser({
                  ...profileData.value!,
                  notificationReadTime
                });
              } else {
                storageService.setUser(profileData.value!);
              }
            }
            mapSectionValue(
              profileData.value?.sections!,
              profileData.value! as any
            );
          }
          if (profileData.value) {
            if (!profileData.value.isProfileCompleted) {
              router?.replace({ name: "notFound" });
              return;
            }
            if (profileData.value.socialLinks) {
              if (!profileData.value.socialLinks.length) {
                if (profileData.value.socialLinks) {
                  profileData.value.socialLinks =
                    SocialLinks.initializeArrayWithData(
                      profileData.value.socialLinks
                    );
                } else {
                  profileData.value.socialLinks = SocialLinks.initialArray;
                }
              }
            }
            images.value.profilePicture = utilService.getUserProfilePicture(
              profileData.value._id!,
              profileData.value.profilePicture!,
              "male",
              false,
              false
            );
            images.value.coverPhoto = utilService.getUserProfilePicture(
              profileData.value._id!,
              profileData.value.coverPhoto!,
              "male",
              true,
              false
            );
            addAsFriend();
            generateMetaTag();
            setTopStatsData();
            profileSectionRef.value = profileData.value.sections;
            if (profileData.value && profileData.value.sections) {
              for (let section of profileData.value.sections) {
                if (
                  currentUser &&
                  currentUser._id &&
                  currentUser._id == profileData.value!._id
                ) {
                  var config = currentUser.profileConfiguration.widgets.find(
                    (x) => x.type == section.type
                  );
                  if (config) {
                    section.privacy = config.status;
                  } else {
                    section.privacy = SettingConfigurationStatus.public;
                  }
                }

                if (section.type == SectionType.portfolio) {
                  if (!section.data) {
                    section.data = {
                      portfolios: [],
                    };
                  }
                  if (section.name == "Portfolio") {
                    section.name = "Favorite Platforms";
                  }
                } else if (section.type == SectionType.traderType) {
                  if (!section.data) {
                    section.data = {
                      traderTypes: [],
                    };
                  }
                } else if (section.type == SectionType.topStats) {
                  if (!section.data) {
                    section.data = {
                      stats: [],
                    };
                  }
                } else if (section.type == SectionType.highlightStats) {
                  if (!section.data) {
                    section.data = {
                      stats: [],
                    };
                  }
                } else if (section.type == SectionType.highlightStatsRight) {
                  if (!section.data) {
                    section.data = {
                      stats: [],
                    };
                  }
                } else if (section.type == SectionType.playbook) {
                  if (!section.data) {
                    section.data = {
                      playbook: [],
                    };
                  }
                } else if (
                  section.type == SectionType.recommendation ||
                  section.type == SectionType.winningTrades ||
                  section.type == SectionType.profitAndLoss ||
                  section.type == SectionType.predictions ||
                  section.type == SectionType.watchlists ||
                  section.type == SectionType.links ||
                  section.type == SectionType.favouriteInfluencer ||
                  section.type == SectionType.points ||
                  section.type == SectionType.images
                ) {
                  if (section.type == SectionType.winningTrades) {
                    if (section.name == "Winning Trades") {
                      section.name = "Best Trades";
                    }
                  }
                  if (!section.data) {
                    section.data = {
                      list: [],
                    };
                  }
                } else if (section.type == SectionType.favouriteInvestment) {
                  if (section.name == "Favourite Investment") {
                    section.name = "Favorite Investments";
                  }
                  if (!section.data) {
                    section.data = {
                      stocks: [],
                    };
                  }
                }
              }
            }
            if (profileData.value && profileData.value?.sections) {
              var section = profileData.value?.sections?.find(
                (x: any) => x.type == SectionType.topStats
              );
              if (section) {
                if (section && section.data && section.data.stats) {
                  for (let stat of section.data.stats) {
                    if (stat.key) {
                      if (stat.key == "viewsCount") {
                        stat.value = profileData.value?.viewsCount;
                      } else if (stat.key == "outreach") {
                        stat.value = profileData.value?.outreach;
                      } else if (stat.key == "followersCount") {
                        stat.value = profileData.value?.followersCount;
                      } else if (stat.key == "followingCount") {
                        stat.value = profileData.value?.followingCount;
                      } else if (stat.key == "friendCount") {
                        stat.value = profileData.value?.friendCount;
                      } else {
                        stat.value = profileData.value?.stats[stat.key];
                      }
                    }
                  }
                  if (section.data.stats && section.data.stats.length) {
                    const filteredArray = section.data.stats.filter(
                      (item: any) => item.value !== 0
                    );
                    section.data.stats = [];
                    section.data.stats = filteredArray;
                    updateProfileSection(section);
                  } else {
                    updateProfileSection(section);
                  }
                }
              }
              var sectionTwo = profileData.value?.sections?.find(
                (x: any) => x.type == SectionType.highlightStats
              );
              if (sectionTwo) {
                if (sectionTwo && sectionTwo.data && sectionTwo.data.stats) {
                  for (let stat of sectionTwo.data.stats) {
                    if (stat.key) {
                      if (stat.key == "viewsCount") {
                        stat.value = profileData.value?.viewsCount;
                      } else if (stat.key == "outreach") {
                        stat.value = profileData.value?.outreach;
                      } else if (stat.key == "followersCount") {
                        stat.value = profileData.value?.followersCount;
                      } else if (stat.key == "followingCount") {
                        stat.value = profileData.value?.followingCount;
                      } else if (stat.key == "friendCount") {
                        stat.value = profileData.value?.friendCount;
                      } else {
                        stat.value = profileData.value?.stats[stat.key];
                      }
                    }
                  }
                  if (sectionTwo.data.stats && sectionTwo.data.stats.length) {
                    const filteredArray = sectionTwo.data.stats.filter(
                      (item: any) => item.value !== 0
                    );
                    sectionTwo.data.stats = [];
                    sectionTwo.data.stats = filteredArray;
                    updateProfileSection(sectionTwo);
                  } else {
                    updateProfileSection(sectionTwo);
                  }
                }
              }
              var sectionThree = profileData.value?.sections?.find(
                (x: any) => x.type == SectionType.highlightStatsRight
              );
              if (sectionThree) {
                if (
                  sectionThree &&
                  sectionThree.data &&
                  sectionThree.data.stats
                ) {
                  for (let stat of sectionThree.data.stats) {
                    if (stat.key) {
                      if (stat.key == "viewsCount") {
                        stat.value = profileData.value?.viewsCount;
                      } else if (stat.key == "outreach") {
                        stat.value = profileData.value?.outreach;
                      } else if (stat.key == "followersCount") {
                        stat.value = profileData.value?.followersCount;
                      } else if (stat.key == "followingCount") {
                        stat.value = profileData.value?.followingCount;
                      } else if (stat.key == "friendCount") {
                        stat.value = profileData.value?.friendCount;
                      } else {
                        stat.value = profileData.value?.stats[stat.key];
                      }
                    }
                  }
                  if (
                    sectionThree.data.stats &&
                    sectionThree.data.stats.length
                  ) {
                    const filteredArray = sectionThree.data.stats.filter(
                      (item: any) => item.value !== 0
                    );
                    sectionThree.data.stats = [];
                    sectionThree.data.stats = filteredArray;
                    updateProfileSection(sectionThree);
                  } else {
                    updateProfileSection(sectionThree);
                  }
                }
              }
            }
          }
        } else {
          utilService.showToast("Error: No User Found", TYPE.ERROR);
          router?.replace({ name: "feed" });
        }
      } catch (e) {
        isProfileLoading.value = false;
        utilService.showToast("Error: Getting Users", TYPE.ERROR);
      }
    } else {
      utilService.showToast("Error: No Username Found", TYPE.ERROR);
    }
    isProfileLoading.value = false;
  };

  const toggleEditableView = (check: boolean, user: any) => {
    isEditableView.value = check;
    document.body.click();
    if (!isEditableView.value) {
      updateUser();
      isProfileChanging.value = true;
      // updateAllSection();
      let info: any[] = user.sections!;
      let infoTwo: any = [];
      if (info && info.length) {
        for (let item of info) {
          let obj = {
            _id: item._id,
            order: item.order,
          };
          infoTwo.push(obj);
        }
      }
      let response = httpService.updateSectionOrders(infoTwo!);
    }
  };

  const addAsFriend = async () => {
    const queryParam = Object.fromEntries(new URLSearchParams(location.search));
    if (profileData.value && queryParam && queryParam.sendConnection) {
      if (queryParam.sendConnection == "true") {
        var loggedInUser = storageService.getUser();
        if (
          loggedInUser &&
          loggedInUser._id &&
          loggedInUser._id != profileData.value._id &&
          !profileData.value.isFriend &&
          !profileData.value.requestSentData
        ) {
          var jsonProfile = JSON.parse(JSON.stringify(profileData.value));
          jsonProfile.privacy = ProfilePrivacy.public;
          profileData.value.isFriend = true;
          profileData.value.isFollowing = true;
          profileData.value.friendsCount!++;
          await sendFriendRequest(jsonProfile._id, true);
        }
      }
      setTimeout(() => {
        router.replace({
          name: RouteEnum.profile,
          params: { username: profileData.value!.userName },
        });
      }, 5000);
    }
    // sendConnection
  };

  const setTopStatsData = () => {
    if (profileData.value && profileData.value.sections) {
      var topStatSectionIndex = profileData.value.sections.findIndex(
        (x) => x.type == SectionType.topStats
      );
      if (
        topStatSectionIndex != -1 &&
        profileData.value.sections[topStatSectionIndex] &&
        profileData.value.sections[topStatSectionIndex].data &&
        profileData.value.sections[topStatSectionIndex].data.stats
      ) {
        var statsArr =
          profileData.value.sections[topStatSectionIndex].data.stats;
        for (let stat of statsArr) {
          if (stat.label == "Followers") {
            stat.value = profileData.value.followersCount;
          } else if (stat.label == "Views") {
            stat.value = profileData.value.viewsCount;
          }
        }
      }
    }
  };

  const checkTextLength = (toTruncate: boolean = true) =>
    utilService.clampText(aboutUser.value, isTruncated, toTruncate);

  const followingUser = async () => {
    document.body.click();
    await onFollow(profileData.value);
  };

  const unFollowingUser = async () => {
    document.body.click();
    var res = await unfollow(profileData.value!._id);
    if (res) {
      profileData.value!.isFollowing = false;
    }
  };

  const cancelRequest = () => {
    onCancelRequest(profileData.value);
  };

  const generateMetaTag = () => {
    const head = document.querySelector("head");
    if (head) {
      var arr = [
        { property: "og:title", content: profileData.value!.fullName },
        { property: "og:description", content: profileData.value!.bio },
        { property: "og:image", content: images.value.profilePicture },
        { property: "og:type", content: "website" },
        { property: "og:site", content: `${window.location.href}` },
      ];
      for (let item of arr) {
        const metaTag = document.createElement("meta");
        metaTag.setAttribute("name", item.property);
        metaTag.setAttribute("content", item.content);
        head.appendChild(metaTag);
      }
    }
  };

  const favouriteInvestmentStocks = computed(() => {
    if (
      profileData.value &&
      profileData.value.sections &&
      profileData.value.sections.length
    ) {
      var fvtInvst = profileData.value.sections.find(
        (x) => x.type == SectionType.favouriteInvestment
      );
      if (fvtInvst && fvtInvst.data && fvtInvst.data.stocks) {
        return fvtInvst.data.stocks;
      }
    }
    return null;
  });

  const allAttributes = computed<any[]>(() => {
    var arr: any[] = [];
    if (
      profileData.value &&
      profileData.value.sections &&
      profileData.value.sections.length
    ) {
      var section = profileData.value.sections.find(
        (x) => x.type == SectionType.traderType
      );
      if (section && section.data && section.data.traderTypes) {
        for (let item of section.data.traderTypes) {
          if (item.name.includes("Trader")) {
            item.name = item.name.replace("Trader", "Trading");
          } else if (item.name === "Scalper") {
            item.name = "Scalping";
          }
          arr.push(item);
        }
      }
    }
    return arr;
  });

  const allLeftStats = computed<any[]>(() => {
    var arr: any[] = [];
    if (
      profileData.value &&
      profileData.value.sections &&
      profileData.value.sections.length
    ) {
      var section = profileData.value.sections.find(
        (x) => x.type == SectionType.highlightStats
      );
      if (section && section.data && section.data.stats) {
        return section.data.stats;
      }
    }
    return arr;
  });

  const allRightStats = computed<any[]>(() => {
    var arr: any[] = [];
    if (
      profileData.value &&
      profileData.value.sections &&
      profileData.value.sections.length
    ) {
      var section = profileData.value.sections.find(
        (x) => x.type == SectionType.highlightStatsRight
      );
      if (section && section.data && section.data.stats) {
        return section.data.stats;
      }
    }
    return arr;
  });

  const handleProfilePhotoUpload = async (event: any, user: ProfileEntity) => {
    try {
      isProfileUploading.value = true;
      if (event.target.files && event.target.files.length) {
        await firebaseService.uploadImage(
          event.target.files[0],
          `profilepicture_${user._id}_v_${user.profilePicture! + 1}`
        );
        user.profilePicture! += 1;
        images.value.profilePicture = utilService.getUserProfilePicture(
          user?._id!,
          user?.profilePicture!,
          "male",
          false,
          false
        );
      }
      isProfileUploading.value = false;
    } catch (err) {
      isProfileUploading.value = false;
    }
  };

  const handleCoverPhotoUpload = async (event: any, user: ProfileEntity) => {
    try {
      isCoverUploading.value = true;
      if (event.target.files && event.target.files.length) {
        await firebaseService.uploadImage(
          event.target.files[0],
          `coverphoto_${user._id}_v_${user.coverPhoto! + 1}`
        );
        user.coverPhoto! += 1;
        images.value.coverPhoto = utilService.getUserProfilePicture(
          user?._id!,
          user?.coverPhoto!,
          "male",
          true,
          false
        );
      }
      isCoverUploading.value = false;
    } catch (err) {
      isCoverUploading.value = false;
    }
  };

  const resetToDefault = async (user: ProfileEntity) => {
    try {
      isCoverUploading.value = true;
      user.coverPhoto = 0;
      images.value.coverPhoto = utilService.getUserProfilePicture(
        user?._id!,
        user?.coverPhoto!,
        "male",
        true,
        false
      );
      isCoverUploading.value = false;
    } catch (err) {
      isCoverUploading.value = false;
    }
  };

  const showEditableBio = (currentUser: User) => {
    if (currentUser && currentUser._id == profileData.value!._id) {
      checkTextLength(false);
      isProfileBioEditable.value = true;
      isProfileNameEditable.value = false;
    }
  };

  const showEditableName = (currentUser: User) => {
    if (currentUser && currentUser._id == profileData.value!._id) {
      isProfileNameEditable.value = true;
      isProfileBioEditable.value = false;
    }
  };

  const onBlurRemoveEditableFields = (ev: any, field: string) => {
    isProfileBioEditable.value = false;
    isProfileNameEditable.value = false;
    setTimeout(() => {
      moreLess.value = false;
    });
    var profData: any = profileData.value;
    if (field) {
      profData[field] = ev.target.innerText || ev.target.value;
    }
    updateUser();
  };

  const updatePrivacy = () => {
    // var currentUser = storageService.getUser();
    const currentUser = {...profileData!.value!}
    for (let section of profileSectionRef.value) {
      if (
        currentUser &&
        currentUser._id &&
        currentUser._id == profileData.value!._id
      ) {
        var config = currentUser.profileConfiguration.widgets.find(
          (x) => x.type == section.type
        );
        if (config) {
          config.status = section.privacy;
        }
        profileData.value!.profileConfiguration =
          currentUser.profileConfiguration;
      }
    }
  };

  const updateUser = () => {
    updatePrivacy();
    var obj = JSON.parse(JSON.stringify(profileData.value));
    delete obj.sections;
    delete obj.favouriteStocks;
    delete obj.trading;
    utilService.showToast("Profile updated");
    httpService
      .updateUser(obj)
      .then((res) => {
        if (res.isSuccess) {
          setTimeout(() => checkTextLength(), 200);
          var stUser = storageService.getUser();
          if (profileData.value!._id == stUser._id) {
            stUser.bio = profileData.value?.bio;
            storageService.setUser(stUser);
            updateUserStats(res.data.stats);
          }
        }
      })
      .catch((e) => {});
  };

  const updateProfileSection = (section: Section) => {
    if (profileData.value) {
      var profileSectionIndex = profileData.value!.sections?.findIndex(
        (x) => x._id == section._id
      );
      if (profileSectionIndex != -1) {
        profileData.value!.sections![profileSectionIndex!].data = section.data;
      }
      mapSectionFromProfileSection(section);
    }
  };

  const scrollToElement = (selector: string) => {
    const element = document.querySelector(selector);
    
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth', // Smooth scroll animation
        block: 'start'      // Scroll to the top of the element
      });
    } else {
      console.error(`Element with selector '${selector}' not found.`);
    }
  }
  const handleStatsClick = (item: any, userId: string) => {
    if (!isEditableView.value) {
      if (item.key == "predictionCount") {
        scrollToElement('[data-type="predictions"]')
      }
      if (item.key == "favoriteInvestmentCount") {
        scrollToElement('[data-type="favouriteInvestment"]')
      }
      if (item.key == "followersCount") {
        router.push({ name: "profileFollowers", params: { userId } });
      }
      if (item.key == "followingCount") {
        router.push({ name: "profileFollowing", params: { userId } });
      }
    }
  };

  const loadPublicWatchList = (id: any) => {
    return new Promise((resolve, reject) => {
      try {
        if (
          profileData.value &&
          profileData.value.sections &&
          profileData.value.sections.length
        ) {
          var section = profileData.value?.sections?.find(
            (x) => x.type == SectionType.watchlists
          );
          if (section && section.data && section.data.list) {
            httpService
              .getWatchlistByIds(section.data.list)
              .then((watchlistRes) => {
                if (watchlistRes.isSuccess && watchlistRes.data) {
                  resolve(watchlistRes.data.filter((x: any) => x));
                }
              })
              .catch((e) => {
                resolve(null);
              });
          }
        } else {
          httpService
            .getPublicWatchList(id)
            .then((res) => {
              if (res.data.isSuccess && res.data.data) {
                resolve(res.data.data);
              }
            })
            .catch((error) => {
              resolve(null);
            });
        }
      } catch (e) {
        resolve(null);
      }
    });
  };

  const loadPrivateWatchList = async () => {
    var res = await httpService.getPrivateWatchList();
    if (res.data.isSuccess && res.data.data) {
      return res.data.data;
    }
    return null;
  };

  const deleteWatchlistItem = async (id: any, index: any, data: any) => {
    try {
      const response = await httpService.deleteWatchlist(id);
      if (response.data.isSuccess) {
        utilService.showToast("Watchlist Deleted");
        data.value.splice(index, 1);
      } else {
      }
    } catch (e) {}
  };

  const updateUserStats = (userStats: any) => {
    var user = storageService.getUser();
    try {
      user.stats = userStats;
      storageService.setUser(user);
    } catch (e) {}
    updateStatsSection(SectionType.topStats, user.stats);
    updateStatsSection(SectionType.highlightStats, user.stats);
    updateStatsSection(SectionType.highlightStatsRight, user.stats);
  };

  const updateStatsSection = (sectionType: SectionType, user: any) => {
    try {
      var section = profileData.value?.sections?.find(
        (x) => x.type == sectionType
      );
      if (section && section.data && section.data.stats) {
        for (let stat of section.data.stats) {
          if (stat.key) {
            if (stat.key == "viewsCount") {
              user.viewsCount;
            } else if (stat.key == "outreach") {
              user.outreach;
            } else if (stat.key == "followersCount") {
              user.followersCount;
            } else if (stat.key == "followingCount") {
              user.followingCount;
            } else if (stat.key == "friendCount") {
              user.friendCount;
            } else if (stat.key === "avgGainPrice") {
              stat.value = 0;
              stat.value = user.avgGainPrice;
            } else if (stat.key === "avgGain") {
              stat.value = 0;
              stat.value = user.avgGain;
            } else {
              stat.value = user.stats[stat.key];
            }
          }
        }
        if (section.data.stats && section.data.stats.length) {
          const filteredArray = section.data.stats.filter(
            (item: any) => item.value !== 0
          );
          section.data.stats = [];
          section.data.stats = filteredArray;
          updateProfileSection(section);
        } else {
          updateProfileSection(section);
        }
      }
    } catch (e) {}
  };

  const updateInBestTrade = (id: string) => {
    try {
      var bestTradeSection = profileData.value?.sections?.find(
        (x) => x.type == SectionType.winningTrades
      );
      if (bestTradeSection) {
        var ind = bestTradeSection.data.list.findIndex((x: string) => x == id);
        if (ind != -1) {
          bestTradeSection.data.list.splice(ind, 1);
        }
      }
    } catch (e) {}
  };

  return {
    updateInBestTrade,
    updateUserStats,
    aboutUser,
    rightValue,
    isProfileLoading,
    allLeftStats,
    allRightStats,
    isFollowingLoading,
    profileData,
    requestReaction,
    images,
    isTruncated,
    favouriteInvestmentStocks,
    allAttributes,
    isProfileNameEditable,
    isProfileBioEditable,
    isEditableView,
    getProfileData,
    updateAllSection,
    isProfileUploading,
    singleWatchlistData,
    isCoverUploading,
    showEditableBio,
    showEditableName,
    updateUser,
    onBlurRemoveEditableFields,
    handleProfilePhotoUpload,
    cancelRequest,
    handleCoverPhotoUpload,
    loadProfileScreen,
    followingUser,
    handleStatsClick,
    userName,
    myElement,
    unFollowingUser,
    updateProfileSection,
    publicWatchlist,
    publicInfluencers,
    isProfileChanging,
    privateWatchlist,
    resetToDefault,
    loadPublicWatchList,
    loadPrivateWatchList,
    deleteWatchlistItem,
    publicBestTrades,
    sharedObject,
    toggleEditableView,
    errorUserName,
    moreLess,
    tabs
  };
};
