import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "QRCodeMainFrmDv QRCodeMainFrmDvSpaceTop" }
const _hoisted_2 = { class: "bodyDv" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShareQR = _resolveComponent("ShareQR")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "headerDv headerInline" }, [
      _createElementVNode("div", { class: "leftDv" }, [
        _createElementVNode("h2", null, "Share QR Code"),
        _createElementVNode("p", null, "Instantly access information by scanning this QR code!")
      ]),
      _createElementVNode("div", { class: "rightDv" })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_scrollbar, { class: "bodyScrollDv" }, {
        default: _withCtx(() => [
          _createVNode(_component_ShareQR)
        ]),
        _: 1
      })
    ])
  ]))
}