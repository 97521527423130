import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "selection_preview" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h6", null, "Section Preview", -1)),
    _createElementVNode("p", null, [
      _createTextVNode(_toDisplayString(_ctx.count) + " ", 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.count <= 1 ? "Item" : "Items"), 1)
    ])
  ]))
}