<template>
  <div class="sparkline_container" v-if="series">
    <apexchart
      :type="chartType"
      height="100%"
      width="100%"
      ref="chart"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import VueApexCharts from "vue3-apexcharts";

export default defineComponent({
  components: {
    apexchart: VueApexCharts,
  },
  props: ["isPositive", "data", "isLine", "color", "delay"],
  setup(props) {
    const chartColor = ref(props.color ? props.color : props.isPositive ? "#70e10e" : "#ff3b3b");
    const chartType = ref<string>(props.isLine ? "line" : "area");
    const chartOptions = ref<any>({
      chart: {
        type: chartType.value,
        height: "32px",
        width: "100%",
        sparkline: {
          enabled: true,
        },
      },
      stroke: {
        width: 2,
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.5,
          opacityTo: 0,
        },
      },
      colors: [chartColor.value],
      tooltip: {
        enabled: false,
      },
    });
    const series = ref<any>();

    // Reference to the chart
    const chart = ref<any>(null);

    // Watch for changes in isPositive prop and update the chart options
    watch(
      () => props.isPositive,
      (newVal) => {
        chartColor.value = props.color ? props.color : newVal ? "#70e10e" : "#ff3b3b";
        chartOptions.value.colors = [chartColor.value];

        // Manually update the chart options to trigger a re-render
        if (chart.value?.updateOptions) {
          chart.value.updateOptions({ colors: [chartColor.value] });
        }
      }
    );

    onMounted(() => {
      var delay = 0;
      if (props.delay != 0) {
        delay = props.delay * 500;
      }
      setTimeout(() => {
        if (props.data && props.data.length) {
          series.value = props.data;
        } else {
          series.value = [
            {
              data: Array.from({ length: 20 }, () =>
                Math.floor(Math.random() * 10)
              ),
            },
          ];
        }
      }, delay);
    });

    return {
      chartOptions,
      series,
      chartType,
      chart, // expose chart ref to the template
    };
  },
});
</script>

<style lang="scss" scoped>
.sparkline_container {
  max-height: 60px;
  overflow: hidden;
  border-radius: 0 0 10px 10px;
  min-width: 100%;
  width: 100%;
  .vue-apexcharts {
    min-height: unset !important;
    height: 50px !important;
    width: 100% !important;
  }
}
.UI-of-chart{
  .sparkline_container {
  max-height: 150px;

  .vue-apexcharts {
    height: 100px !important;
  }
}
}
@media (max-width: 1366px) {
  .sparkline_container {
    max-height: 48px;
  }
}
</style>
