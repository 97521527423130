class Stocks {
    stockId: string | undefined;
    companyName: string | undefined;
    country: string | undefined;
    price: number | undefined;
    classification: string | undefined;
    datatype: string | undefined;
    datetime: string | undefined;
    entitlement: string | undefined;
    equityinfo: string | undefined;
    fundamentals: string | undefined;
    key: string | undefined;
    pricedata: string | undefined;
    status: string | undefined;
    symbolstring: string | undefined;
    symbol: string | undefined;

    static copy(payload: any) {
        let stock = new Stocks()
        stock = { ...payload }
        if (payload.equityinfo && payload.pricedata) {
            stock.stockId = payload.equityinfo['shortname'] ?? payload.symbolstring;
            stock.companyName = payload.equityinfo['longname'];
            stock.price = payload.pricedata['last'];
            stock.country = 'USA'
        }
        return stock;
    }
}

enum StockColumns {
    symbol = "symbol", exchange = "exchange", exShName = "exShName", exLgName = "exLgName", longname = "longname", shortname = "shortname", last = "last", change = "change", tick = "tick", open = "open", high = "high", low = "low", prevclose = "prevclose", bid = "bid", ask = "ask",
    bidsize = "bidsize", asksize = "asksize", rawbidsize = "rawbidsize", rawasksize = "rawasksize", tradevolume = "tradevolume", sharevolume = "sharevolume", vwap = "vwap", lasttradedatetime = "lasttradedatetime", lastquotedatetime = "lastquotedatetime",
    askmarketidentificationcode = "askmarketidentificationcode", lastmarketidentificationcode = "lastmarketidentificationcode", bidmarketidentificationcode = "bidmarketidentificationcode", totalvalue = "totalvalue", close = "close",
    imbalance = "imbalance", changepercent = "changepercent", shareclasslevelsharesoutstanding = "shareclasslevelsharesoutstanding", sharesoutstanding = "sharesoutstanding", marketcap = "marketcap", eps = "eps", peratio = "peratio", pbratio = "pbratio",
    beta = "beta", week52high = "week52high", week52highvwap = "week52highvwap", week52lowvwap = "week52lowvwap", totalsharesoutstanding = "totalsharesoutstanding", week52highvol = "week52highvol", week52lowvol = "week52lowvol", week52avgvol = "week52avgvol",
    dividend = "dividend", sharesescrow = "sharesescrow", week52low = "week52low", avgvol30days = "avgvol30days", float = "float", sector = "sector", industry = "industry", symbolstring = "symbolstring", datatype = "datatype", entitlement = "entitlement", datetime = "datetime"
}

class StockTagModel {
    stockId: string | undefined;
    symbol: string;
    market: string;

    constructor(symbol: string = "", market: string = "", stockId: string | undefined = undefined) {
        this.stockId = stockId;
        this.symbol = symbol;
        this.market = market;
    }
}

enum StockMarkets {
    stocks = "stocks",
    etf = "ETFs",
    Futures = "futures",
    crypto = "crypto",
    nft = "nft",
    indices = "indices",
    options = "options",
}


enum StockMarketColors {
    stocks = "#6600ff",
    etf = "#598AFF",
    Futures = "#39D3D3",
    crypto = "#FF851B",
    nft = "#FF3B3B",
    indices = "#FFF01B",
    options = "#FF1B9C",
}

export {
    Stocks,
    StockMarkets,
    StockTagModel,
    StockMarketColors,
    StockColumns,
}