<template>
  <figure
    v-if="isPlaceholder"
    class="stock_logo placeholder"
    @click.stop="goToStockDetail"
    @touchstart="goToStockDetail"
    :class="{ placeholderWithContent: placeholderContent }"
    :style="{
      ...style,
      width: size?.width,
      height: size?.height,
      flex: `0 0 ${size?.width}`,
    }"
  >
    <figcaption v-if="placeholderContent">
      {{ `${placeholderContent}+` }}
    </figcaption>
  </figure>
  <figure
    v-else
    class="stock_logo"
    :class="market"
    @touchstart="goToStockDetail"
    @click.stop="goToStockDetail"
    :style="{
      ...style,
      width: size?.width,
      height: size?.height,
      flex: `0 0 ${size?.width}`,
    }"
  >
    <template v-if="stock">
      <img
        v-if="$filters.validStockLogoUrl(stock.primaryLogoUrl)"
        @error="
          (event) =>
            onImgLoadError(
              event,
              stock?.secondaryLogoUrl,
              stock?.tertiaryLogoUrl
            )
        "
        ref="imgTag"
        :src="stock.primaryLogoUrl"
        :style="{
          width: size?.width,
          height: size?.height,
          flex: `0 0 ${size?.width}`,
        }"
      />
      <img
        ref="imgTag"
        @error="
          (event) =>
            onImgLoadError(
              event,
              stock?.secondaryLogoUrl,
              stock?.tertiaryLogoUrl
            )
        "
        v-else
        :src="getStockImage(symbol, market)"
        :style="{
          width: size?.width,
          height: size?.height,
          flex: `0 0 ${size?.width}`,
        }"
      />
      <!-- <div
        v-if="stockData && !hideHover"
        class="hoverEffectDv"
        ref="hoverWidget"
        :style="{
          top: rectArea.top + 'px',
          left: rectArea.left + 'px',
          position: 'fixed',
        }"
      >
        <StockHoverWidget
          :imgUrl="imgUrl"
          :symbol="symbol"
          :sparklineData="sparklineData"
          :market="market"
          :stockData="stockData"
        />
      </div> -->
    </template>
  </figure>
</template>

<script lang="ts">
import { useStockSocket } from "@/composables/stock_socket.composable";
import { StockMarkets } from "@/helpers/models/stock/stocks.model";
import { StockSocketTransferDTO } from "@/helpers/models/stock/stock_socket_transfer.dto";
import { RouteEnum } from "@/router/routeNames";
import utilService from "@/services/util.service";
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { popModal } from "jenesius-vue-modal";
import { useSocketStockData } from "@/composables/stock_data.composable";
import filters from "@/helpers/filters";

export default defineComponent({
  props: {
    stockId: String,
    imgUrl: String,
    symbol: String,
    market: String,
    isPlaceholder: Boolean,
    size: Object,
    placeholderContent: Number,
    disableRouting: Boolean,
    style: Object,
    hideHover: Boolean,
    // stockData: SocketStock,
    sparklineData: Object,
  },
  setup(props) {
    const getStockImage = utilService.getStockImage;
    const router = useRouter();
    const stockId = ref<any>(props.stockId!);

    const initialObject = () => {
      return {
        _id: stockId.value,
        type: props?.market,
        symbol: props?.symbol,
        stockId: stockId.value,
        logoUrl: props?.imgUrl,
      };
    };

    const onImgLoadError = (
      er: any,
      secondaryLogoUrl: any,
      tertiaryLogoUrl: any
    ) => {
      try {
        if (secondaryLogoUrl) {
          er.srcElement.src = secondaryLogoUrl;
          er.srcElement.onerror = () => {
            if (tertiaryLogoUrl) {
              er.srcElement.src = tertiaryLogoUrl;
            }
          };
        }
      } catch (e) {
        console.error("Image load error:", e);
      }
    };

    const { addStockId } = useStockSocket();

    onMounted(() => {
      if (props.market == "crypto") {
        stockId.value = `${props.symbol}_CRYPTO`;
      }
      addStockId(new StockSocketTransferDTO(stockId.value));
      updateStockInfoObject(initialObject());
    });

    const { stockData: stock, updateStockInfoObject } = useSocketStockData(
      initialObject()
    );

    watch(props, () => {
      updateStockInfoObject(initialObject());
    });

    const goToStockDetail = (ev: any) => {
      if (!props.disableRouting) {
        try {
          ev.stopPropagation();
        } catch (e) {}
        popModal();
        router.push({
          name: RouteEnum.stockDetail,
          params: { stockId: props.stockId },
        });
      }
    };

    const imgTag = ref<any>();

    return {
      imgTag,
      onImgLoadError,
      stock,
      goToStockDetail,
      getStockImage,
    };
  },
});
</script>
<style lang="scss">

.hoverEffectDv {
  display: none;
  z-index: 99999;
  &:hover {
    z-index: 999;
    display: block;
  }
}

.stock_logo {
  overflow: visible !important;
  padding: 0px;
  border-radius: 10px;
  border: 2px solid transparent;
  cursor: pointer;
  background: var(--white-color);
  border-color: var(--color-icon-check);
  width: 40px;
  flex: 0 0 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &.large {
    width: 40px;
    flex: 0 0 40px;
    height: 40px;
  }

  // img {
  //   border-radius: 8px;
  // }
  &.placeholder {
    background: var(--base-dark);
    border-color: var(--card-background-color);
  }
  &:hover {
    .hoverEffectDv {
      display: block;
      z-index: 99999;
    }
  }
  img {
    object-fit: contain;
    display: block;
    border-radius: 8px;
  }
  &.ETFs {
    border-color: var(--color-light-purple11) !important;
  }
  &.futures {
    border-color: var(--cyan) !important;
  }
  &.crypto {
    border-color: var(--highlight-color) !important;
  }
  &.nft {
    border-color: var(--red) !important;
  }
  &.indices {
    border-color: var(--color-new-light-yollow2) !important;
  }
  &.options {
    border-color: var(--pink1) !important;
  }
}

.placeholderWithContent {
  text-align: center;
  background-color: var(--modal-background-color) !important;
  border: 3px solid var(--color-grey4) !important;
  padding: 5.6px;
  color: var(--color-new-light-grey2);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media only screen and (min-width: 1280px) and (max-width: 1700px)  {
    .stock_logo{
        width: 30px !important;
        flex: 0 0 30px !important;
        height: 30px !important;
        border-radius: 8px !important;
        border-width: 2px !important;
        padding: 0 !important;
        margin-right: 4px;
        img{
            border-radius: 6px !important;
        }
    }
}
</style>
