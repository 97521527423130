import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "viewPlaybookCardDv"
}
const _hoisted_2 = { class: "playbookinnerCardTag" }
const _hoisted_3 = { class: "iteminfoDv firstinfoDv" }
const _hoisted_4 = { class: "textDv" }
const _hoisted_5 = { class: "iteminfoDv secondinfoDv" }
const _hoisted_6 = { class: "restoreInfoDv tockactualprice" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "restoreInfoDv stockstopprice" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "iteminfoDv threeinfoDv" }
const _hoisted_11 = { class: "restoreInfoDv stocktargetoneprice" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "restoreInfoDv stocktargettwoprice" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { class: "iteminfoDv fourthinfoDv" }
const _hoisted_16 = { class: "viewPlayBookPopoverDv" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = {
  key: 1,
  style: {"text-align":"center"}
}
const _hoisted_19 = {
  key: 2,
  class: "footerviewInfoDv"
}
const _hoisted_20 = { class: "firstDv" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconComponent = _resolveComponent("IconComponent")!
  const _component_StockImageTag = _resolveComponent("StockImageTag")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_ProfileAvatarComponent = _resolveComponent("ProfileAvatarComponent")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_ctx.stock)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.showRemove)
          ? (_openBlock(), _createBlock(_component_IconComponent, {
              key: 0,
              class: "newiconDv",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onRemove()), ["stop"])),
              onTouchstart: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onRemove())),
              id: `minusWining${_ctx.index}`,
              icon: "minus.svg"
            }, null, 8, ["id"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.stock.traderType === 'long')
            ? (_openBlock(), _createBlock(_component_IconComponent, {
                key: 0,
                class: "cardTagcontainer",
                icon: "icon-playbook-long.svg"
              }))
            : _createCommentVNode("", true),
          (_ctx.stock.traderType === 'short')
            ? (_openBlock(), _createBlock(_component_IconComponent, {
                key: 1,
                class: "cardTagcontainer",
                icon: "icon-playbook-short.svg"
              }))
            : _createCommentVNode("", true),
          _createElementVNode("h2", null, _toDisplayString(_ctx.stock.traderType), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_StockImageTag, {
            stockId: _ctx.stock.stockId,
            imgUrl: _ctx.stock.logoUrl,
            symbol: _ctx.stock.symbol,
            market: _ctx.stock.type
          }, null, 8, ["stockId", "imgUrl", "symbol", "market"]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.stock.symbol), 1),
            _createVNode(_component_el_tooltip, {
              class: "box-item",
              effect: "dark",
              content: _ctx.stock.name,
              placement: "top-start"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.stock.name), 1)
              ]),
              _: 1
            }, 8, ["content"])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_IconComponent, {
              class: "iconinfoDv",
              icon: "icon-playbook-stockactualprice.svg"
            }),
            _createElementVNode("h3", {
              ref: "priceElement",
              innerHTML: 
            _ctx.$filters.systemNumberConvention(
              _ctx.stock.entry,
              true,
              false,
              _ctx.widthCalculating
            )
          
            }, null, 8, _hoisted_7)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_IconComponent, {
              class: "iconinfoDv",
              icon: "icon-playbook-stockstopprice.svg"
            }),
            _createElementVNode("h3", {
              ref: "priceElement",
              innerHTML: 
            _ctx.$filters.systemNumberConvention(
              _ctx.stock.stopLoss,
              true,
              false,
              _ctx.widthCalculating
            )
          
            }, null, 8, _hoisted_9)
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_IconComponent, {
              class: "iconinfoDv",
              icon: "icon-playbook-stocktargetoneprice.svg"
            }),
            _createElementVNode("h3", {
              ref: "priceElement",
              innerHTML: 
            _ctx.$filters.systemNumberConvention(
              _ctx.stock.targetOne,
              true,
              false,
              _ctx.widthCalculating
            )
          
            }, null, 8, _hoisted_12)
          ]),
          _createElementVNode("div", _hoisted_13, [
            (_ctx.stock.traderType === 'long')
              ? (_openBlock(), _createBlock(_component_IconComponent, {
                  key: 0,
                  class: "iconinfoDv",
                  icon: "icon-playbook-stocktargettwoprice.svg"
                }))
              : _createCommentVNode("", true),
            (_ctx.stock.traderType === 'short')
              ? (_openBlock(), _createBlock(_component_IconComponent, {
                  key: 1,
                  class: "iconinfoDv",
                  icon: "icon-playbook-stocktargettwopriceshort.svg"
                }))
              : _createCommentVNode("", true),
            _createElementVNode("h3", {
              ref: "priceElement",
              innerHTML: 
            _ctx.$filters.systemNumberConvention(
              _ctx.stock.targetTwo,
              true,
              false,
              _ctx.widthCalculating
            )
          
            }, null, 8, _hoisted_14)
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_el_popover, {
            trigger: "hover",
            "popper-class": "cardStyled dark",
            class: "cardStyled dark",
            "popper-style": "\r\n          background:var(--modal-background-color);\r\n           box-shadow: 0px 0px 25px #191527 !important;\r\n                     border: 0px solid var(--el-border-color-light) !important;\r\n          padding: 0px;border-radius:8px;height: auto;width:250px"
          }, {
            reference: _withCtx(() => [
              _createVNode(_component_IconComponent, {
                class: "iconinfoDv",
                icon: "icon-playbook-stocknotes.svg"
              })
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_16, [
                (_ctx.stock.notes)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(_ctx.stock.notes), 1))
                  : _createCommentVNode("", true),
                (!_ctx.stock.notes)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_18, " No Notes Found! "))
                  : _createCommentVNode("", true),
                (_ctx.user && _ctx.stock.notes)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        (_openBlock(), _createBlock(_component_ProfileAvatarComponent, {
                          key: _ctx.images.profilePicture,
                          profileImage: _ctx.images.profilePicture,
                          border: true,
                          badges: false,
                          class: "small extraProfileDv"
                        }, null, 8, ["profileImage"])),
                        _createElementVNode("h2", null, _toDisplayString(_ctx.user.fullName), 1)
                      ]),
                      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "endDv" }, [
                        _createElementVNode("h2", null, "2 hrs ago")
                      ], -1))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}