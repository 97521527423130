import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "textDv" }
const _hoisted_2 = { class: "headinginfoDv" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconComponent = _resolveComponent("IconComponent")!
  const _component_StockImageTag = _resolveComponent("StockImageTag")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["stockItemCard", { removeable: _ctx.showRemoveIcon }]),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goToStockDetail && _ctx.goToStockDetail(...args)))
  }, [
    (_ctx.stock)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.showRemoveIcon)
            ? (_openBlock(), _createBlock(_component_IconComponent, {
                key: 0,
                class: "iconDv",
                icon: "minus.svg",
                id: `minusStock${_ctx.stock.stockId}`,
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onRemove()), ["stop"]))
              }, null, 8, ["id"]))
            : _createCommentVNode("", true),
          _createVNode(_component_StockImageTag, {
            stockId: _ctx.stock._id,
            imgUrl: _ctx.stock.logoUrl,
            symbol: _ctx.stock.symbol,
            market: _ctx.stock.type,
            disableRouting: _ctx.disableRouting
          }, null, 8, ["stockId", "imgUrl", "symbol", "market", "disableRouting"]),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.isOverflowing)
                ? (_openBlock(), _createBlock(_component_el_tooltip, {
                    key: 0,
                    class: "box-item",
                    effect: "dark",
                    content: _ctx.stockData.symbol,
                    placement: "top-start"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("h1", { ref: "symbolElement" }, _toDisplayString(_ctx.stockData.symbol), 513)
                    ]),
                    _: 1
                  }, 8, ["content"]))
                : (_openBlock(), _createElementBlock("h1", {
                    key: 1,
                    ref: "symbolElement"
                  }, _toDisplayString(_ctx.stockData.symbol), 513)),
              (!_ctx.fromSearchStock)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 2,
                    class: _normalizeClass({ downward: _ctx.stock.changesPercentage < 0 })
                  }, [
                    _createVNode(_component_IconComponent, {
                      class: "priceIcon",
                      icon: "stock-pricing-up-icon.svg"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$filters.systemNumberConvention(
                _ctx.stock.changesPercentage,
                false,
                false
              )) + "%", 1)
                  ], 2))
                : _createCommentVNode("", true)
            ]),
            (_ctx.fromSearchStock)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.stock.name), 1))
              : _createCommentVNode("", true),
            _createElementVNode("p", {
              ref: "priceElement",
              innerHTML: 
            _ctx.$filters.systemNumberConvention(_ctx.stock.price, true, false, 110)
          
            }, null, 8, _hoisted_4)
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 2))
}