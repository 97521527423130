import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createElementBlock as _createElementBlock, Fragment as _Fragment, createVNode as _createVNode, renderList as _renderList, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "firstDv"
}
const _hoisted_2 = {
  key: 1,
  class: "firstDv"
}
const _hoisted_3 = {
  key: 0,
  class: "firstDv"
}
const _hoisted_4 = { class: "inputPlaybookDv" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "inputPlaybookDv" }
const _hoisted_7 = {
  key: 0,
  class: "dateInfoDvSelect"
}
const _hoisted_8 = {
  key: 3,
  class: "endDv",
  ref: "stockDvContainer"
}
const _hoisted_9 = {
  key: 0,
  class: "viewMoreDv"
}
const _hoisted_10 = {
  key: 4,
  class: "endDv",
  ref: "stockDvContainer"
}
const _hoisted_11 = { class: "itemStockDv" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconComponent = _resolveComponent("IconComponent")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_StockImageTag = _resolveComponent("StockImageTag")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["playbookCardItem", { editModeInfo: _ctx.editMode }]),
    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.OpenViewPopup()))
  }, [
    (_ctx.showRemove)
      ? (_openBlock(), _createBlock(_component_IconComponent, {
          key: 0,
          class: "iconDv",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onRemove())),
          onTouchstart: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onRemove())),
          id: `minusWining${_ctx.index}`,
          icon: "minus.svg"
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    _cache[6] || (_cache[6] = _createElementVNode("span", { class: "card--tag color-darkgreen" }, "Playbook", -1)),
    (!_ctx.editMode)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.trade)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_ctx.isOverflowing)
                  ? (_openBlock(), _createBlock(_component_el_tooltip, {
                      key: 0,
                      class: "box-item",
                      effect: "dark",
                      content: _ctx.trade.title,
                      placement: "top-start"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("h1", { ref: "title" }, _toDisplayString(_ctx.trade.title), 513)
                      ]),
                      _: 1
                    }, 8, ["content"]))
                  : (_openBlock(), _createElementBlock("h1", {
                      key: 1,
                      ref: "title"
                    }, _toDisplayString(_ctx.trade.title), 513)),
                _createElementVNode("p", null, _toDisplayString(_ctx.dateInfo), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[5] || (_cache[5] = [
                _createElementVNode("h1", null, "-", -1),
                _createElementVNode("p", null, "-", -1)
              ])))
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (_ctx.trade)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_el_input, {
                    placeholder: "ex Top Gainer",
                    class: "WinningTradeinputDv playbookFormTitleFeild",
                    maxlength: "25",
                    max: "25",
                    type: "text",
                    modelValue: _ctx.trade.title,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.trade.title) = $event))
                  }, null, 8, ["modelValue"]),
                  (!_ctx.trade.title)
                    ? (_openBlock(), _createElementBlock("h5", _hoisted_5, "Enter Title"))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_el_date_picker, {
                    "disabled-date": _ctx.disabledDate,
                    modelValue: _ctx.trade.timestamp,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.trade.timestamp) = $event)),
                    class: "ProfitAndLossinputDv newInputSecondDv playbookFormTitleFeild",
                    type: "date",
                    placeholder: "Select Date"
                  }, null, 8, ["disabled-date", "modelValue"]),
                  (!_ctx.trade.timestamp)
                    ? (_openBlock(), _createElementBlock("h5", _hoisted_7, "Select Date"))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ], 64)),
    (_ctx.stocksInfo && _ctx.stocksInfo.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stocksInfo, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "itemStockDv",
              key: item
            }, [
              _createVNode(_component_StockImageTag, {
                disableRouting: true,
                stockId: item.stockId,
                imgUrl: item.logoUrl,
                symbol: item.symbol,
                market: item.type
              }, null, 8, ["stockId", "imgUrl", "symbol", "market"])
            ]))
          }), 128)),
          (_ctx.maxToShow && _ctx.trade.stocks && _ctx.trade.stocks.length > _ctx.maxToShow)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("p", null, "+" + _toDisplayString(_ctx.trade.stocks.length - _ctx.maxToShow), 1)
              ]))
            : _createCommentVNode("", true)
        ], 512))
      : (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_StockImageTag)
          ])
        ], 512))
  ], 2))
}