import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "profile_container" }
const _hoisted_2 = {
  key: 0,
  class: "bordered"
}
const _hoisted_3 = { class: "spacing" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  class: "lock"
}
const _hoisted_7 = {
  key: 0,
  class: "polybadge"
}
const _hoisted_8 = { class: "spacing c-pointer" }
const _hoisted_9 = { class: "spacing c-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconComponent = _resolveComponent("IconComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.border)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "polytar",
      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.goToProfile($event)), ["exact","self"]))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          (_ctx.image)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: _normalizeClass(_ctx.isLocked ? 'opacity' : ''),
                src: _ctx.image,
                onError: _cache[0] || (_cache[0] = (event) => (event.srcElement.src = _ctx.getProfileImageAlt()))
              }, null, 42, _hoisted_4))
            : (_openBlock(), _createElementBlock("img", {
                key: 1,
                class: _normalizeClass(_ctx.isLocked ? 'opacity' : ''),
                src: _ctx.getProfileImageAlt()
              }, null, 10, _hoisted_5))
        ]),
        (_ctx.isLocked)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_IconComponent, {
                path: "",
                class: "",
                icon: 'lock.svg'
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.badges)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["polybadge", { info_profile: _ctx.profileUser }]),
          onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
        }, _cache[6] || (_cache[6] = [
          _createElementVNode("div", { class: "spacing" }, "5", -1)
        ]), 2))
      : _createCommentVNode("", true),
    (_ctx.camera)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (_ctx.isProfileUploading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[7] || (_cache[7] = [
                _createElementVNode("div", { class: "spacing" }, [
                  _createElementVNode("div", { class: "loader" })
                ], -1)
              ])))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "polybadge camera",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$refs.file.click()))
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_IconComponent, {
                    path: "",
                    class: "s-white",
                    icon: "edit-1-svgrepo-com.svg"
                  }),
                  _createElementVNode("input", {
                    ref: "file",
                    onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.uploadFile && _ctx.uploadFile(...args))),
                    class: "file-upload d-hidden",
                    type: "file",
                    accept: "image/*"
                  }, null, 544)
                ])
              ]))
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.showQRCode)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: "polybadge camera",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openQrShareModal()))
        }, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_IconComponent, {
              path: "",
              icon: "icon-qrcode.svg"
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}