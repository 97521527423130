import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "stock_stats"
}
const _hoisted_2 = {
  key: 0,
  class: "statsRow"
}
const _hoisted_3 = {
  class: "secondInfoStockDv",
  ref: "scrollElem"
}
const _hoisted_4 = {
  key: 0,
  class: "ColorBlack"
}
const _hoisted_5 = {
  class: "custom-scrollbar",
  ref: "scrollBar"
}
const _hoisted_6 = {
  key: 0,
  class: "ColorBlack"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_ctx.fromProfileSharePopup)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (!_ctx.hiddenInfo)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_ctx.hideStocksName)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalStats, (stat) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "stat",
                        key: stat
                      }, [
                        _createElementVNode("span", {
                          class: "color",
                          style: _normalizeStyle({
              background: stat.color,
            })
                        }, null, 4),
                        _createElementVNode("p", null, [
                          _createTextVNode(_toDisplayString(_ctx.$filters.convertToTitleCase(stat.title)), 1),
                          (_ctx.showCount)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode("(" + _toDisplayString(stat.count) + ")", 1)
                              ], 64))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalStats, (stat) => {
            return (_openBlock(), _createBlock(_component_el_tooltip, {
              key: stat,
              class: "box-item",
              effect: "dark",
              content: stat.title.toUpperCase() + '(' + stat.count + ')',
              placement: "top-start"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(["stat", {
              borderChanging: _ctx.totalStats && _ctx.totalStats.length === 1,
            }]),
                  style: _normalizeStyle({
              maxWidth: stat.maxWidth == 0 ? '2%' : stat.maxWidth + '%',
            })
                }, [
                  (stat.percent !== 'NaN' && Math.floor(stat.percent) !== 0)
                    ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(Math.floor(stat.percent)) + "% ", 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", {
                    class: "color",
                    style: _normalizeStyle({
                background: stat.color,
              })
                  }, null, 4)
                ], 6)
              ]),
              _: 2
            }, 1032, ["content"]))
          }), 128))
        ], 512)
      ], 64))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (!_ctx.hiddenInfo)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["scroll-container stock_stats", {
        fromqrcodescreen: _ctx.fromqrcodescreen,
        shareSection: _ctx.sectionShare,
      }])
            }, [
              _createElementVNode("div", _hoisted_5, null, 512),
              (_ctx.hideStocksName)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["scroll-content statsRow", {
          fromSideMenu: _ctx.fromSideMenu,
        }]),
                    ref: "scrollElem"
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalStats, (stat) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "stat",
                        key: stat
                      }, [
                        _createElementVNode("span", {
                          class: "color",
                          style: _normalizeStyle({
              background: stat.color,
            })
                        }, null, 4),
                        _createElementVNode("p", null, [
                          _createTextVNode(_toDisplayString(_ctx.$filters.convertToTitleCase(stat.title)), 1),
                          (_ctx.showCount)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode("(" + _toDisplayString(stat.count) + ")", 1)
                              ], 64))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    }), 128))
                  ], 2))
                : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["secondInfoStockDv", {
        fromqrcodescreen: _ctx.fromqrcodescreen,
        shareSection: _ctx.sectionShare,
        fromSideMenuNew: _ctx.fromSideMenu,
      }]),
          ref: "scrollElem"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalStats, (stat) => {
            return (_openBlock(), _createBlock(_component_el_tooltip, {
              key: stat,
              style: {"cursor":"default"},
              class: "box-item",
              effect: "dark",
              content: stat.title.toUpperCase() + '(' + stat.count + ')',
              placement: "top-start"
            }, {
              default: _withCtx(() => [
                (stat.maxWidth > 0)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["stat", {
              oneStats: _ctx.totalStats && _ctx.totalStats.length === 1,
            }]),
                      style: _normalizeStyle({
              maxWidth: stat.maxWidth == 0 ? '2%' : stat.maxWidth + '%',
            })
                    }, [
                      (stat.percent !== 'NaN' && Math.floor(stat.percent) !== 0)
                        ? (_openBlock(), _createElementBlock("h2", _hoisted_6, _toDisplayString(Math.floor(stat.percent)) + "% ", 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", {
                        class: "color",
                        style: _normalizeStyle({
                background: stat.color,
              })
                      }, null, 4)
                    ], 6))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["content"]))
          }), 128))
        ], 2)
      ], 64))
}