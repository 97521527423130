import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal confirmationDialogWrapper" }
const _hoisted_2 = { class: "confirmationDialog" }
const _hoisted_3 = { class: "confirmationDialog_buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h6", null, _toDisplayString(_ctx.message), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "btn btn--outline btn--sm",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCancel()))
        }, _toDisplayString(_ctx.cancelText), 1),
        _createElementVNode("button", {
          class: "btn btn--primary btn--sm",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onConfirm()))
        }, _toDisplayString(_ctx.confirmationText), 1)
      ])
    ])
  ]))
}