import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "stock-select" }
const _hoisted_2 = {
  key: 0,
  class: "stock-select-container-search"
}
const _hoisted_3 = { class: "autocomplete-stock" }
const _hoisted_4 = {
  key: 0,
  class: "stock-select-active fromplaybook"
}
const _hoisted_5 = {
  key: 1,
  class: "stock-select-active"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StockImageTag = _resolveComponent("StockImageTag")!
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_StockItemWidget = _resolveComponent("StockItemWidget")!
  const _component_IconComponent = _resolveComponent("IconComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["stock-select-container", {
        watchlistStockSelectComponent: _ctx.fromWatchlist,
      }])
    }, [
      _cache[6] || (_cache[6] = _createElementVNode("h6", null, "Ticker", -1)),
      (!_ctx.selectedStock || (_ctx.hideSelected && _ctx.selectedStock))
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_el_autocomplete, {
              modelValue: _ctx.stockKeyword,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.stockKeyword) = $event)),
              "fetch-suggestions": _ctx.onStockSearch,
              "popper-class": "stock-autocomplete",
              placeholder: "Search Stock",
              onSelect: _ctx.onStockSelect,
              onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isStockFieldFocused = true)),
              onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isStockFieldFocused = false))
            }, {
              default: _withCtx(({ item }) => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_StockImageTag, {
                    stockId: item.stockId,
                    disableRouting: true,
                    imgUrl: item.logoUrl,
                    symbol: item.symbol,
                    market: item.type
                  }, null, 8, ["stockId", "imgUrl", "symbol", "market"]),
                  _createElementVNode("h6", null, [
                    _createTextVNode(_toDisplayString(item.symbol) + " ", 1),
                    _createElementVNode("span", null, _toDisplayString(item.name), 1)
                  ])
                ])
              ]),
              _: 1
            }, 8, ["modelValue", "fetch-suggestions", "onSelect"])
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.selectedStock && !_ctx.hideSelected)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.fromPlaybook)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_StockItemWidget, {
                  disableRouting: true,
                  stockData: _ctx.selectedStock
                }, null, 8, ["stockData"]),
                _createVNode(_component_IconComponent, {
                  class: "iconDv",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.removeSelectedStock())),
                  onTouchstart: _cache[4] || (_cache[4] = ($event: any) => (_ctx.removeSelectedStock())),
                  icon: "minus.svg"
                })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_StockItemWidget, {
                  disableRouting: true,
                  stockData: _ctx.selectedStock
                }, null, 8, ["stockData"]),
                _createElementVNode("a", {
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.removeSelectedStock()))
                }, [
                  _createVNode(_component_IconComponent, { icon: "icon-closing-remove.svg" }),
                  _cache[7] || (_cache[7] = _createElementVNode("p", null, "Remove", -1))
                ])
              ]))
        ], 64))
      : _createCommentVNode("", true)
  ]))
}