import { SocketStock } from "@/helpers/models/stock/socket_stock.model";
import { StockSocketTransferDTO } from "@/helpers/models/stock/stock_socket_transfer.dto";
import utilService from "@/services/util.service";
import { Socket, io } from "socket.io-client";
import { onMounted } from "vue";

let socket: Socket;

var stockIds: StockSocketTransferDTO[] = [];
var stocksData: any = {};
var stocks:any = []

export const useStockSocket = () => {
  var timeout: any;

  const debounceOnInput = (func: any, ms: number = 1000) => {
    clearTimeout(timeout);
    timeout = setTimeout((e: any) => func(e), ms);
  };

  const connectToSocket = () => {
    socket = io(utilService.getLiveHostURL());
    socket.on("connect", () => {
      listenStockFromStockIds();
      socket.on("priceupdate", (data) => {
        let obj = JSON.parse(data);
        let findingStockIndex = stocks.findIndex(
          (x: any) => x.symbol === obj.symbol
        );
        if (findingStockIndex != -1) {
          if(stocks[findingStockIndex].exchange !== 'CRYPTO'){
            // let changePrice:any = (((obj.price - stocksData[stocks[findingStockIndex]["stockId"]].price) / stocksData[stocks[findingStockIndex]["stockId"]].price) * 100).toFixed(2)
            // if(changePrice != 0){
            //   stocksData[stocks[findingStockIndex]["stockId"]].changesPercentage = changePrice;
            // }
            if(stocksData[stocks[findingStockIndex]["stockId"]].changesPercentage != obj.changesPercentage){
              stocksData[stocks[findingStockIndex]["stockId"]].changesPercentage = obj.changesPercentage
            }
            if(stocksData[stocks[findingStockIndex]["stockId"]].price != obj.price){
              stocksData[stocks[findingStockIndex]["stockId"]].price = obj.price;
            }
          }
        }
      });
    });
  };

  const disconnectFromSocket = () => {
    socket.removeAllListeners();
    socket.close();
  };

  var count = 1;
  const listenStockFromStockIds = () => {
    if (socket.connected && !document.hidden) {
      if (stockIds.length) {
        count++;
        socket.emit(
          "getstocksdata",
          JSON.stringify({ stocks: stockIds }),
          (data: any) => {
            if (data && data.length) {
              for (let stock of data) {
                if (stock && stock.stockId) {
                  let index = stocks.findIndex((x:any)=>x.stockId === stock.stockId);
                  if(index === -1){
                    stocksData[stock.stockId] = stock;
                    stocks.push(stock);
                  }
                }
              }
            }
          }
        );
      }
    }
    setTimeout(() => {
      listenStockFromStockIds();
    }, 3000);
  };

  const addStockId = (stockObj: StockSocketTransferDTO) => {
    if (stockObj) {
      // stockObj.showTrendChart
      checkStockExistence(stockObj.stockId!, true);
    }
  };

  const clearStockIds = () => {
    stockIds = [];
  };

  const getDataByStockId = (stockId: string) => {
    if (stocksData && stocksData[stockId]) {
      return stocksData[stockId];
    }
    return null;
  };

  const checkStockExistence = (stockId: string, showChart: boolean = false) => {
    if (stockId) {
      var index = stockIds.findIndex((x) => x.stockId == stockId);
      if (index == -1) {
        stockIds.push(new StockSocketTransferDTO(stockId, showChart));
      } else if (stockIds[index].showTrendChart != showChart) {
        stockIds[index].showTrendChart = showChart;
      }
      return true;
    }
    return null;
  };

  const searchStocks = (keyword: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        socket.emit("searchStockByKeyword", keyword, (data: SocketStock[]) => {
          if (!data) {
            data = [];
          }
          resolve(data.slice(0, 40));
        });
      } catch (e) {
        resolve([]);
      }
    });
  };

  return {
    socket,
    debounceOnInput,
    stockIds,
    searchStocks,
    connectToSocket,
    disconnectFromSocket,
    addStockId,
    clearStockIds,
    getDataByStockId,
    checkStockExistence,
  };
};
