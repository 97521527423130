import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "app-modal AddAndEditImages onAddPopup addNoteModal" }
const _hoisted_2 = { class: "app-modal--body" }
const _hoisted_3 = { class: "textAreaInfoDv" }
const _hoisted_4 = { class: "gridInfo" }
const _hoisted_5 = { class: "iteminfo" }
const _hoisted_6 = { class: "searchinfoDv placeholderinfodv" }
const _hoisted_7 = { class: "scrollable-container" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "app-modal--footer" }
const _hoisted_10 = { class: "app-modal--footer-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "app-modal--head" }, [
      _createElementVNode("h6", null, "Add Notes")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_scrollbar, { class: "bodyScrollDv" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[5] || (_cache[5] = _createElementVNode("h3", null, "Comments", -1)),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_el_input, {
                      rows: "5",
                      class: "newInputDv",
                      type: "textarea",
                      min: "4",
                      minlength: "4",
                      maxlength: "100",
                      max: "100",
                      modelValue: _ctx.notes,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.notes) = $event)),
                      placeholder: "Enter Points"
                    }, null, 8, ["modelValue"])
                  ]),
                  (!_ctx.notes)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_8, "Please Enter Comment"))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("button", {
          class: "btn btn--outline",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onCancel())),
          onTouchstart: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onCancel()))
        }, " Cancel ", 32),
        _createElementVNode("button", {
          class: _normalizeClass([{ disabled: _ctx.notes == '' }, "btn btn--primary"]),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onSave())),
          onTouchstart: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onSave()))
        }, " Save ", 34)
      ])
    ])
  ]))
}