<template>
  <router-view />
  <widget-container-modal />
</template>

<script lang="ts">
import { useStockSocket } from "@/composables/stock_socket.composable";
import { initializeApp } from "firebase/app";
import { container } from "jenesius-vue-modal";
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { provideToast, TYPE } from "vue-toastification";
import { useFCMNotification } from "./composables/fcm.notification.composable";
import { AppEnvironments, Environment } from "./environment";
import { ResendVerificationDTO } from "./helpers/models/resendverification.dto";
import { SystemConfig } from "./helpers/models/system-config";
import { RouteEnum } from "./router/routeNames";
import firebaseService from "./services/firebase.service";
import httpService from "./services/http.service";
import storageService from "./services/storage.service";
import utilService from "./services/util.service";
import { useProfileScreenComposable } from "./views/private/profile-screen/composables/profile.screen.composable";
import { usePredictionSocket } from "./composables/prediction_socket.composable";

export default defineComponent({
  components: { WidgetContainerModal: container },
  setup() {
    provideToast({ timeout: 3000 });

    const router = useRouter();
    const { connectToSocket, disconnectFromSocket } = useStockSocket();
    const { connectToPredictionSocket, disconnectPredictionFromSocket } =
      usePredictionSocket();
    const route = useRoute();
    const { registerForNotification, registerServiceWorker } =
      useFCMNotification();
    const backgroundCont = ref();
    const getNotificationPermission = async () => {
      try {
        if ("Notification" in window) {
          var permission = await Notification.requestPermission();
        }
      } catch (e) {}
    };

    onMounted(async () => {
      try {
        registerServiceWorker();
        const popover = document.querySelector(".el-popover");
        if (popover) {
          popover.addEventListener("click", (event) => {
            event.stopPropagation();
            // ... other popover actions
          });
        }
        await initializeApp(utilService.FirebaseConfig);
        connectToSocket();
        connectToPredictionSocket();

        let response: any = await httpService.getSystemConfiguration("feature");
        if (response) {
          storageService.setSystemConfiguration(response.data.data);
        }

        // let info = {
        //   dashboard: true,
        //   feed: false,
        //   portfolio: false,
        //   analyze: false,
        //   market: false,
        //   socialize: false,
        //   articles_overview: false,
        //   settings: true,
        //   notication: true,
        //   header: true,
        //   sidemenu: false,
        //   reportaBug: true,
        //   headerSearch: true,
        //   createPost: false,
        //   profileTabs: false,
        // };
        // storageService.setSystemConfiguration(info);
        if (
          location.pathname.toLowerCase().includes("resetpassword") ||
          location.pathname.toLowerCase().includes("verifyemail")
        ) {
          storageService.RemoveAllProperties();
          return;
        }
        if (location.pathname.toLowerCase().includes("twitter")) {
          return;
        }
        if (location.pathname.toLowerCase().includes("_linked_in")) {
          return;
        }
        if (location.pathname.toLowerCase().includes("_reddit")) {
          return;
        }
        try {
          await getNotificationPermission();
        } catch (e) {}
        var token = storageService.getToken();
        var user = storageService.getUser();
        if (token != null && user != null && user._id) {
          storageService.setBackground(user);
          if (
            user.isEmailVerified == false &&
            user.email &&
            user.email.length
          ) {
            storageService.RemoveAllProperties();
            storageService.resendVerifyEmailDTO = new ResendVerificationDTO(
              user.email!,
              user.fullName!
            );
            router.push({ name: "locked" });
            return;
          }
          if (
            user.isEmailVerified != true &&
            user.isPhoneVerified == false &&
            user.phone &&
            user.phone.length
          ) {
            router.push({
              name: "otpVerification",
              query: { backToLogin: "1" },
            });
            return;
          }
          firebaseService.anonymousLogin();
          if (!user.isGuestUser) {
            try {
              updateLoggedInUser(user._id);
            } catch (e) {}
            try {
              await getNotificationPermission();
              await registerForNotification();
            } catch (e) {}
            const { loadProfileScreen, updateUserStats, isEditableView } =
              useProfileScreenComposable();
            isEditableView.value = false;
            if (!route.params.username) {
              loadProfileScreen(storageService.getUser().userName!);
            }
            updateUserStats(storageService.getUser().stats);
            if (!user.isProfileCompleted) {
              router.replace({ name: "createProfile" });
              return;
            }
          }
          if (
            !route.meta.requiresAuth &&
            route.name != RouteEnum.profile &&
            !location.href.includes("profile/user")
          ) {
            // router.push({
            //   name: "feed",
            // });
          }
          return;
        } else if (
          (route.meta.requiresAuth || !route.name) &&
          !location.pathname.includes("user")
        ) {
          router.push({ name: "signin" });
        }
      } catch (e: any) {
        utilService.showToast(e.message, TYPE.ERROR);
      }
    });

    const updateLoggedInUser = (userId: string) => {
      httpService
        .getUserById(userId)
        .then((res) => {
          if (res.isSuccess) {
            storageService.setUser(res.data);
          }
        })
        .catch((e) => {});
    };

    onBeforeUnmount(() => {
      disconnectFromSocket();
      disconnectPredictionFromSocket();
    });
    return {
      backgroundCont,
    };
  },
});
</script>
<style lang="scss">
.background_container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  + * {
    z-index: 10;
  }
}
</style>
