<template>
  <div class="selection_preview">
    <h6>Section Preview</h6>
    <p>{{ count }} <span> {{ count <= 1 ? "Item" : "Items" }}</span></p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
  setup() {},
});
</script>

<style lang="scss" scoped>
$modal-gap:var(--app-modal-inner-gap);
.selection_preview{
  display: flex;align-items: center;justify-content: space-between;margin-bottom: $modal-gap;
  h6{
    margin: 0;text-align: center;font-weight: 600;font-size: var( --font-2md);line-height: 24px;color: var(--orange);
  }
  p{
    margin: 0;text-align: center;font-weight: 600;font-size: var( --font-2md);line-height: 24px;color: var(--base-text);
    span{font-size: var( --font-2md);line-height: 21px;font-weight: 500;}
  }
}
</style>