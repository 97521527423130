import utilService from "@/services/util.service";
import themeService, { TraderverseThemes } from "@/services/theme.service";

const filters = {
  systemNumberConvention(
    input: any,
    isPrice: boolean = false,
    isAbs: boolean = false,
    containerWidth: number = 50,
    isRound: boolean = true,
    fromChart: boolean = false
  ) {
    try {
      if (input == null || isNaN(input)) {
        return "0";
      }
      if (typeof input == "string") {
        input = parseFloat(input);
      }
      var isNegative = false;
      if (input < 0) {
        isNegative = true;
      }
      input = Math.abs(input);
      var result: any = 0;
      var charToAdd = "";
      if (isRound) {
        if (input >= 1.0e12) {
          var trillion: any = (input / 1.0e12).toFixed(2);
          trillion = Math.round(trillion * 100) / 100;
          result = parseFloat(trillion).toString();
          charToAdd = "T";
        } else if (input >= 1.0e9) {
          var billion: any = (input / 1.0e9).toFixed(2);
          billion = Math.round(billion * 100) / 100;
          result = parseFloat(billion).toString();
          charToAdd = "B";
        } else if (input >= 1.0e6) {
          var million: any = (input / 1.0e6).toFixed(2);
          million = Math.round(million * 100) / 100;
          result = parseFloat(million).toString();
          charToAdd = "M";
        } else if (input >= 10000) {
          result = (input / 1000).toFixed(2);
          result = parseFloat(result).toString();
          charToAdd = "K";
        } else if (input >= 1000) {
          result = input.toFixed(0);
        } else if (input >= 100) {
          result = input.toFixed(2);
          result = parseFloat(result).toString();
        } else if (input >= 10) {
          result = input.toFixed(2);
          result = parseFloat(result).toString();
        } else if (input < 1 && input >= 0.0001) {
          result = input.toFixed(4);
          result = parseFloat(result).toString();
        } else if (input < 0.0001) {
          result = this.fetchLowestValues(input, containerWidth);
        } else {
          result = input.toFixed(2);
          result = parseFloat(result).toString();
        }
      } else {
        result = input.toFixed(2);
        result = parseFloat(result).toString();
      }

      if (isAbs) {
        result = Math.abs(result);
      }
      if (charToAdd) {
        result += charToAdd;
      }

      if (isPrice) {
        if (input >= 1000) {
          if (!isNaN(result) && typeof result === "string") {
            result = Number(result).toLocaleString();
          }
        }
        if (isNegative && !isAbs) {
          if (fromChart) {
            result = result;
          } else {
            result = `<span class="DollarSignChanging">$</span>${result}`;
          }
        } else {
          if (fromChart) {
            result = result;
          } else {
            result = `<span class="DollarSignChanging">$</span>${result}`;
          }
        }
      } else {
        if (input < 1000 && input >= 1) {
          result = +input;
          result = Math.round(result * 100) / 100;
          result = +result;
        } else if (input < 1) {
          result = +input;
          result = result.toFixed(2);
          if (!isAbs && !isPrice && result == 0) {
            result = +input;
          }
        }
        if (isNegative && !isAbs) {
          result = `${result}`;
        }
      }
      return result;
    } catch (e) {
      return 0;
    }
  },

  fetchLowestValues(input: number, containerWidth: number) {
    let fontSize = 14;
    let [mantissaStr, exponentStr] = input.toExponential().split("e");
    let exponent = parseInt(exponentStr, 10);
    let mantissa = parseFloat(mantissaStr);
    if (exponent < 0) {
      let leadingZeros = Math.abs(exponent) - 2;
      const maxCharacters = Math.floor(containerWidth / fontSize);
      let decimalPlaces = Math.max(0, maxCharacters - 4);
      let significantDigits = (mantissa * Math.pow(10, leadingZeros))
        .toFixed(decimalPlaces)
        .replace(".", "")
        .replace(/0+$/, "")
        .substring(0, decimalPlaces);
      let result = `0.0{${leadingZeros}}${significantDigits}`;
      return result;
    } else {
      return input.toString();
    }
  },

  calculateDecimalPlaces(number: any, containerWidth: any) {
    let fontSize = 14;
    const numberStr = number.toString();
    const integerPartLength = numberStr.split(".")[0].length;
    const availableWidth = containerWidth - integerPartLength * fontSize;
    const maxDecimalDigits = Math.max(0, Math.floor(availableWidth / fontSize));
    return maxDecimalDigits;
  },

  systemNumberConventionOld(
    input: any,
    isPrice: boolean = false,
    isAbs: boolean = false
  ) {
    try {
      if (input == null || isNaN(input)) {
        return "0";
      }
      if (typeof input == "string") {
        input = parseFloat(input);
      }
      var result: any = 0;
      var charToAdd = "";
      if (input >= 1.0e12) {
        var trillion = (input / 1.0e12).toFixed(2);
        trillion = trillion.replace(/\.00$/, "").replace(/\.0$/, "");
        if (trillion.slice(0, trillion.indexOf(".")).length > 1) {
          result = trillion.slice(0, -1);
        } else {
          result = trillion;
        }
        charToAdd = "T";
      } else if (input >= 1.0e9) {
        var billion = (input / 1.0e9).toFixed(2);
        billion = billion.replace(/\.00$/, "").replace(/\.0$/, "");
        if (billion.slice(0, billion.indexOf(".")).length > 1) {
          result = billion.slice(0, -1);
        } else {
          result = billion;
        }
        charToAdd = "B";
      } else if (input >= 1.0e6) {
        var million = (input / 1.0e6).toFixed(2);
        million = million.replace(/\.00$/, "").replace(/\.0$/, "");
        if (million.slice(0, million.indexOf(".")).length > 1) {
          result = million.slice(0, -1);
        } else {
          result = million;
        }
        charToAdd = "M";
      } else if (input >= 10000) {
        result = (input / 10000).toFixed(1);
        charToAdd = "K";
      } else if (input >= 1000) {
        result = input.toFixed(0);
      } else if (input >= 100) {
        result = input.toFixed(1);
      } else if (input >= 10) {
        result = input.toFixed(2);
      } else {
        result = input.toFixed(3);
      }
      while (
        result[result.length - 1] == "0" ||
        result[result.length - 1] == "."
      ) {
        var toBreakLoop = result[result.length - 1] == ".";
        result = result.slice(0, result.length - 1);
        if (toBreakLoop) {
          break;
        }
      }
      if (isAbs) {
        result = Math.abs(result);
      }
      if (charToAdd) {
        result += charToAdd;
      }
      if (isPrice) {
        result = `$${result}`;
      }
      return result;
    } catch (e) {
      return 0;
    }
  },

  getComputedVariable(varName: string) {
    if (themeService.currentTheme == TraderverseThemes.light) {
      return utilService.chartTheme["light"][varName];
    } else {
      return utilService.chartTheme["default"][varName];
    }
  },

  insertSpaceBeforeCapitalLetter(s: string) {
    var val = s.replace(/([A-Z])/g, " $1").trim();
    return val;
  },

  validStockLogoUrl(url: string) {
    return url && url != "n/a" && !url.includes("http:");
  },

  convertToTitleCase(val: string) {
    try {
      if (val) {
        if (val.toLowerCase() == "nft") {
          return val.toUpperCase();
        }
        if (val.includes(" ")) {
          return val
            .split(" ")
            .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
            .join(" ");
        } else {
          return `${val[0].toUpperCase()}${val.substring(1)}`.trim();
        }
      } else {
        return "";
      }
    } catch (e) {
      return val;
    }
  },

  convertToUpperCase(val: string) {
    if (val) {
      return val.toUpperCase();
    } else {
      return "";
    }
  },

  formatDate(value: number, format: string = "MMM dd, yyyy") {
    if (value) {
      const months: string[] = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];
      const date = new Date(value);
      const year = date.getFullYear();
      const monthIndex = date.getMonth();
      const day = date.getDate().toString().padStart(2, "0");

      // For month in numeric format with leading zero
      const monthNumeric = (monthIndex + 1).toString().padStart(2, "0");
      // For month in alphabetic format
      const monthAlpha = months[monthIndex];

      // Replace the format string with actual values
      let formattedDate = format
        .replace("yyyy", year.toString())
        .replace("mm", monthNumeric)
        .replace("dd", day)
        .replace("MMM", monthAlpha);

      return formattedDate;
    }
    return "";
  },

  parseNumberIfInString(stat: string) {
    if (!stat) {
      return 0;
    }
    var pattern = /[a-zA-Z]/;
    if (!pattern.test(stat)) {
      stat = filters.systemNumberConvention(stat);
    }
    return stat;
  },

  validateNumberInput(
    evt: any,
    limit: number = -1,
    allowDecimal: boolean = true,
    positiveOnly: boolean = false
  ) {
    const isDecimalPoint = allowDecimal && evt.key === ".";
    const input = typeof evt === "string" ? evt : evt.target.value + evt.key;

    if (
      // Allow backspace, delete, and decimal point if allowed
      evt.key === "Backspace" ||
      evt.key === "Delete" ||
      isDecimalPoint ||
      // Allow numbers
      (!isNaN(Number(evt.key)) && !evt.ctrlKey) ||
      // Allow Ctrl+A, Ctrl+C, Ctrl+V
      (evt.ctrlKey && ["a", "c", "v"].includes(evt.key.toLowerCase())) ||
      // Allow if length is within limit
      (limit !== -1 && input.length <= limit)
    ) {
      // If limit is set, check if the input value exceeds the limit
      if (limit !== -1) {
        const inputValue = parseFloat(input);
        if (isNaN(inputValue) || inputValue > limit) {
          evt.preventDefault();
        }
      }
      if (positiveOnly && input.includes("-") || input <= 0 ) {
        evt.preventDefault();
      }
    } else {
      evt.preventDefault();
    }
  },

  validateStockSymbol(symbol: string): string {
    // if (symbol.includes('.')) {
    //   symbol = symbol.split('.')[0];
    // }
    if (symbol?.includes("-")) {
      symbol = symbol.split("-")[0];
    }
    if (symbol?.length > 6) {
      symbol = symbol.slice(0, 6);
    }
    return symbol;
  },

  randomGeneratedValue() {
    return Math.floor(Math.random() * (30000 - 800 + 1)) + 800;
  },

  getStatsValue(key: string, value: any) {
    var isPrice = true;

    if (
      key == "followersCount" ||
      key == "followingCount" ||
      key == "friendCount" ||
      key == "investmentsCount" ||
      key == "trades" ||
      key == "avgTradesPerMonth" ||
      key == "avgTradesPerWeek" ||
      key == "viewsCount" ||
      key == "outreach" ||
      key == "avgGain" ||
      key == "topGain" ||
      key == "tradesThisMonth" ||
      key == "tradesThisWeek" ||
      key == "todayTrades" ||
      key == "tradesThisYear" ||
      key == "traderTypeCount" ||
      key == "bestTradeCount" ||
      key == "favoritePlatformCount" ||
      key == "recommendationCount" ||
      key == "watchListCount" ||
      key == "listCount" ||
      key == "linksCount" ||
      key == "imagesCount" ||
      key == "favoriteInfluencerCount" ||
      key == "favoriteInvestmentCount" ||
      key == "playbookCount" ||
      key == "profitAndLossCount" ||
      key == "predictionCount" ||
      key == "socialLnksCount"
    ) {
      isPrice = false;
    }
    var newValue = value;
    newValue = filters.systemNumberConvention(
      newValue,
      isPrice,
      false,
      50,
      isPrice
    );

    if (!isNaN(newValue)) {
      newValue = newValue.toString();
      newValue = parseFloat(newValue);
    }
    if (key == "avgGain" || key == "topGain") {
      newValue += "%";
    }
    return newValue;
  },

  getProfitPercentage(purchasedPrice: number, soldPrice: number) {
    const profit = soldPrice - purchasedPrice;
    if (purchasedPrice === 0) {
      return "Cannot calculate profit percentage when purchase price is zero.";
    }
    const profitPercentage = (profit / purchasedPrice) * 100;
    return profitPercentage || NaN;
  },

  getClassByProfit(purchasedPrice: number, soldPrice: number) {
    const profitPercentage = +this.getProfitPercentage(
      purchasedPrice,
      soldPrice
    );
    let profitClass = "ProfitDisabled";
    if (profitPercentage >= 20 && profitPercentage < 50) {
      profitClass = "bronze";
    } else if (profitPercentage >= 50 && profitPercentage < 100) {
      profitClass = "silver";
    } else if (profitPercentage >= 100) {
      profitClass = "gold";
    } else {
      profitClass = "profitDisabled";
    }

    return profitClass;
  },

  formatNumber(num: number) {
    if (isNaN(num) || typeof num !== "number") {
      return num;
    }
    if (num < 1000) {
      return num; // Return the number directly if less than 1000
    }

    // Define thresholds for suffixes
    const sizes = [
      { value: 1e12, suffix: "T" },
      { value: 1e9, suffix: "B" },
      { value: 1e6, suffix: "M" },
      { value: 1e3, suffix: "K" },
    ];

    // Iterate through sizes to find appropriate suffix
    for (let i = 0; i < sizes.length; i++) {
      if (num >= sizes[i].value) {
        return (num / sizes[i].value).toFixed(2) + sizes[i].suffix;
      }
    }
  },

  formatNumberWithCommas(num: number) {
    // Check if the provided value is not a number
    if (isNaN(num) || typeof num !== "number") {
      return num;
    }

    // Use toLocaleString to format the number with commas
    return num.toLocaleString();
  },

  processValue(value: number): number {
    if (value > 100) {
      return 100;
    } else if (value % 1 !== 0) {
      // Check if the value has a decimal part
      return Math.floor(value); // Return only the integer part
    } else {
      return value;
    }
  },

  truncateText(text: string, maxLength: number = 45): string {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  },
};

export default filters;
